import gql from "graphql-tag";

export const GET_USER = gql`
  query GetUserInfo {
    userInfo @client
  }
`;

export const GET_PHONE_FROM_NIT = gql`
  query GetUserPhoneByNIT($nit: String!) {
    getUserPhoneByNIT(nit: $nit) {
      phone
      nit
      is_active
    }
  }
`

export const CHECK_TOKEN = gql`
  query CheckToken {
    checkToken {
      status
    }
  }
`;
// Estos son los datos del user en la pagina MyAccount -> datos de empresa 
export const DETAILS = gql`
  query Details {
    details {
      id
      firstname
      lastname
      email
      nit
      phone
      addressId
      addresses {
        id
        firstname
        lastname
        nit
        street
        city
        phone
        reference
        latitude
        longitude
      }
    }
  }
`;
// estos son los datos de historial ordenes
export const ORDERS = gql`
  query Orders {
    orders {
      id
      incrementId
      createdAt
      status
      total
    }
  }
`;
// estos son los datos especificos de una orden
export const ORDER = gql`
  query Order($orderId: Int!) {
    order(orderId: $orderId) {
      id
      incrementId
      createdAt
      status
      billingFirstname
      billingLastname
      billingEmail
      billingNit
      shippingFirstname
      shippingLastname
      shippingPhone
      shippingNit
      shippingStreet
      shippingCity
      shippingReference
      items {
        itemId
        name
        price
        qty
      }
      subtotal
      shippingPrice
      total
    }
  }
`;

export const ORDER_STATUS = gql`
  query SofiawsOrderStatus($incremendId: String!) {
    sofiawsOrderStatus(incremendId: $incremendId) {
      status
    }
  }
`;
