import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./Reset.css";

const LayoutGeneral = React.lazy(() => import(/* webpackChunkName: "LayoutGeneral" */ "./layout/general"));
const Homepage = React.lazy(() => import(/* webpackChunkName: "Homepage" */ "./pages/homepagev2"));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "LoginPage" */ "./pages/login"));
const Product = React.lazy(() => import(/* webpackChunkName: "Product" */ "./pages/product"));
const Checkout = React.lazy(() => import(/* webpackChunkName: "Checkout" */ "./pages/checkout"));
const Faq = React.lazy(() => import(/* webpackChunkName: "Faq" */ "./pages/faq"));
const Terms = React.lazy(() => import(/* webpackChunkName: "Terms" */ "./pages/terms"));
const Historial = React.lazy(() => import(/* webpackChunkName: "Terms" */ "./pages/historial"));
const ThankYou = React.lazy(() => import(/* webpackChunkName: "ThankYou" */ "./pages/thankyou"));
const WelcomePage = React.lazy(() => import(/* webpackChunkName: "Welcome" */ "./pages/welcome"));
const Tracking = React.lazy(() => import(/* webpackChunkName: "Tracking" */ "./pages/tracking_test"));
const Catalogo = React.lazy(() => import(/* webpackChunkName: "Catalogo" */ "./pages/AlgoliaProducts"))
//const Tracking_test = React.lazy(() => import(/* webpackChunkName: "Tracking" */ "./pages/tracking_test"));

const App = () => {
  return (
    <Suspense fallback={<div />}>
      <Router>
        <Switch>
          <Route
            exact
            path="/login"
            children={
              <LayoutGeneral>
                <LoginPage />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/login/:token"
            children={
              <LayoutGeneral>
                <LoginPage />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/bienvenido"
            children={
              <LayoutGeneral>
                <WelcomePage />
              </LayoutGeneral>
            }
          />
          {/* <Route
            path="/segui-tu-pedido"
            children={
              <LayoutGeneral>
                <Tracking />
              </LayoutGeneral>
            }
          /> */}
          <Route
            path="/segui-tu-pedido"
            children={
              <LayoutGeneral>
                <Tracking />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/gracias"
            children={
              <LayoutGeneral>
                <ThankYou />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/"
            children={
              <LayoutGeneral>
                <Homepage />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/password-reset"
            children={
              <LayoutGeneral>
                <Homepage />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/password-reset/:token"
            children={
              <LayoutGeneral>
                <Homepage />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/preguntas-frecuentes"
            children={
              <LayoutGeneral>
                <Faq />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/terminos-y-condiciones"
            children={
              <LayoutGeneral>
                <Terms />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/productos"
            children={
              <LayoutGeneral>
                <Catalogo />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/checkout"
            children={
              <LayoutGeneral>
                <Checkout />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/mi-cuenta"
            children={
              <LayoutGeneral page="historial">
                <Historial />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/productos/:category"
            children={
              <LayoutGeneral>
                <Catalogo />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/productos/:category/:subcategory"
            children={
              <LayoutGeneral>
                <Catalogo />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/productos/:category/:subcategory/:lastlevel"
            children={
              <LayoutGeneral>
                <Catalogo />
              </LayoutGeneral>
            }
          />
          <Route
            exact
            path="/:prodname"
            children={
              <LayoutGeneral page="productpage">
                <Product />
              </LayoutGeneral>
            }
          />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
